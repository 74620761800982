.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    z-index: 40;
}

.loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: loader-animation 1.5s infinite linear alternate;
}

@keyframes loader-animation {
    0% {
        box-shadow: 20px 0 #193456, -20px 0 #d5dbe1;
        background: #193456;
    }
    33% {
        box-shadow: 20px 0 #e9c468, -20px 0 #d5dbe1;
        background: #e9c468;
    }
    66% {
        box-shadow: 20px 0 #d5dbe1, -20px 0 #193456;
        background: #d5dbe1;
    }
    100% {
        box-shadow: 20px 0 #193456, -20px 0 #e9c468;
        background: #193456;
    }
}
